import React from 'react'

const EVMDashboardSvg = () => (
  <svg
    width="24"
    height="24"
    viewBox="1 0 22 22"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M3.39251 15C3.13707 15 2.94081 14.9315 2.80374 14.7944C2.66668 14.6573 2.59814 14.4611 2.59814 14.2057V9.20584C2.59814 8.95039 2.66668 8.75414 2.80374 8.61707C2.94081 8.48001 3.13707 8.41147 3.39251 8.41147H6.62603C6.89394 8.41147 7.09642 8.46755 7.23349 8.57969C7.37055 8.69184 7.43909 8.85382 7.43909 9.06565C7.43909 9.51424 7.16807 9.73853 6.62603 9.73853H4.27098V10.9908H6.43912C6.98116 10.9908 7.25218 11.212 7.25218 11.6543C7.25218 11.8662 7.18364 12.0282 7.04658 12.1403C6.90951 12.2525 6.70703 12.3085 6.43912 12.3085H4.27098V13.673H6.62603C7.16807 13.673 7.43909 13.8973 7.43909 14.3458C7.43909 14.5577 7.37055 14.7197 7.23349 14.8318C7.09642 14.9439 6.89394 15 6.62603 15H3.39251Z"
      fill="currentColor"
    />
    <path
      d="M13.0089 8.81333C13.0774 8.6638 13.174 8.55166 13.2986 8.47689C13.4232 8.40213 13.5603 8.36475 13.7098 8.36475C13.9341 8.36475 14.1366 8.43639 14.3173 8.57969C14.4979 8.72299 14.5883 8.90367 14.5883 9.12173C14.5883 9.22764 14.5602 9.33667 14.5042 9.44882L12.037 14.5421C11.956 14.7103 11.8345 14.8411 11.6725 14.9346C11.5105 15.0218 11.3361 15.0654 11.1492 15.0654C10.9622 15.0654 10.7878 15.0218 10.6258 14.9346C10.4638 14.8411 10.3423 14.7103 10.2613 14.5421L7.79414 9.44882C7.7443 9.34913 7.71938 9.2401 7.71938 9.12173C7.71938 8.9099 7.81283 8.73233 7.99974 8.58904C8.18665 8.43951 8.39536 8.36475 8.62588 8.36475C8.77541 8.36475 8.91248 8.40213 9.03708 8.47689C9.16792 8.55166 9.26761 8.6638 9.33614 8.81333L11.1772 12.7945L13.0089 8.81333Z"
      fill="currentColor"
    />
    <path
      d="M21.1083 8.36475C21.345 8.36475 21.5382 8.44262 21.6877 8.59838C21.8372 8.75414 21.912 8.95662 21.912 9.20584V14.2898C21.912 14.5265 21.8434 14.7134 21.7064 14.8505C21.5693 14.9876 21.3886 15.0561 21.1643 15.0561C20.9463 15.0561 20.7656 14.9876 20.6223 14.8505C20.4852 14.7134 20.4167 14.5265 20.4167 14.2898V11.5422L19.3513 13.5047C19.2454 13.7041 19.1333 13.8474 19.0149 13.9346C18.8965 14.0156 18.7563 14.0561 18.5943 14.0561C18.4323 14.0561 18.2922 14.0156 18.1738 13.9346C18.0554 13.8474 17.9433 13.7041 17.8374 13.5047L16.7813 11.5983V14.2898C16.7813 14.5203 16.7097 14.7072 16.5664 14.8505C16.4293 14.9876 16.2486 15.0561 16.0243 15.0561C15.8063 15.0561 15.6256 14.9876 15.4823 14.8505C15.3452 14.7134 15.2767 14.5265 15.2767 14.2898V9.20584C15.2767 8.95662 15.3515 8.75414 15.501 8.59838C15.6568 8.44262 15.853 8.36475 16.0898 8.36475C16.258 8.36475 16.4106 8.41459 16.5477 8.51427C16.6848 8.60773 16.8 8.74479 16.8935 8.92547L18.613 12.159L20.3232 8.92547C20.5164 8.55166 20.7781 8.36475 21.1083 8.36475Z"
      fill="currentColor"
    />
  </svg>
)

export default EVMDashboardSvg
